import React from 'react'

import logo from '../img/logo.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Santa Dash Brighton"
            style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
        &copy; Brighton Santa Dash 2021
        </div>
      </footer>
    )
  }
}

export default Footer
